<!--
* @description:
* @fileName relatedForum.vue
* @author hvv
* @date 2023/04/27 11:10:18
!-->
<template>
  <div>
    <vab-query-form class="ml-10" style="padding-top: 20px">
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="会议名称">
            <el-input
              v-model="queryForm.meetingName"
              class="mr-10"
              placeholder="请输入会议名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="Search" type="primary" @click="handleQueryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="loading" border :data="list">
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column
        label="会议名称"
        prop="meetingName"
        show-overflow-tooltip
      />
      <el-table-column
        label="会议开始时间"
        prop="startTime"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ handleDate(row.startTime) }}
        </template>
      </el-table-column>
      <el-table-column
        label="会议结束时间"
        prop="endTime"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ handleDate(row.endTime) }}
        </template>
      </el-table-column>
      <el-table-column
        label="关联状态"
        prop="meetingCount"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tag v-if="row.status">已关联</el-tag>
          <el-tag v-else type="danger">未关联</el-tag>
        </template>
      </el-table-column>

      <!-- <el-table-column label="会议码" prop="qrCodeUrl" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image style="width: 50px; height: 50px" :src="row.qrCodeUrl" :zoom-rate="1.2" :preview-src-list="[row.qrCodeUrl]" :initial-index="4" fit="cover" />
        </template>
      </el-table-column> -->

      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="300"
      >
        <template #default="{ row }">
          <div class="d-flex flex-wrap flex-center">
            <template v-if="row.status">
              <el-button
                size="small"
                plain
                type="info"
                @click="handleEdit(row)"
              >
                编辑论坛
              </el-button>
              <el-button
                size="small"
                plain
                type="danger"
                @click="cancelRelatedMeetFunc(row)"
              >
                取消关联
              </el-button>
              <el-button type="primary" size="small" @click="handleQrcode(row)">
                预览二维码
              </el-button>
            </template>
            <el-button
              v-else
              size="small"
              plain
              type="primary"
              @click="handleRelatedMeetFunc(row)"
            >
              关联为展会论坛
            </el-button>
          </div>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>

    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />

    <el-dialog title="二维码" v-model="dialogVisible" width="30%">
      <template v-for="(item, index) in qrcodeUrlList">
        <el-image :src="item.qrcodeUrl" style="margin: 0 auto"></el-image>
        <p style="text-align: center">{{ item.appKey }}</p>
      </template>
    </el-dialog>

    <edit
      ref="editRef"
      :ex-code="queryForm.exMeetingCode"
      @fetch-data="fetchData"
    />
  </div>
</template>

<script >
import {
  defineComponent,
  getCurrentInstance,
  defineAsyncComponent,
  onMounted,
  reactive,
  toRefs,
  onActivated,
} from 'vue'
import {
  getMeetListByHost,
  cancelRelatedMeet,
  getRelatedMeetDetail,
  getQrcodeUrls,
} from '@/api/exhibitionManage'
import { parseTime } from '@/utils/index'
export default defineComponent({
  name: 'relatedForum.vue',
  components: {
    Edit: defineAsyncComponent(() => import('./components/editRelatedMeet')),
  },
  setup() {
    const state = reactive({
      activeName: 'second',
      loading: false,
      list: [],
      editRef: null,
      dialogVisible: false,
      qrcodeUrlList: [],
      total: 0,
      layout: 'total, sizes, prev, pager, next, jumper',
      queryForm: {
        hostUid: '',
        pageNum: 1,
        pageSize: 20,
        meetingName: '',
      },
    })

    const { proxy } = getCurrentInstance()
    const handleEdit = async (row) => {
      const { data } = await getRelatedMeetDetail({
        meetingCode: row.meetingCode,
      })
      data.status = row.status
      state['editRef'].showEdit(data)
    }
    const handleClick = () => {}

    const fetchData = async () => {
      const { data } = await getMeetListByHost(state.queryForm)
      console.log(data)
      state.list = data.data
      state.total = data.total
    }

    onActivated(() => {
      console.log(proxy.$route)
      state.queryForm.hostUid = proxy.$route.query.hostUid
      state.queryForm.exMeetingCode = proxy.$route.query.exCode
      fetchData()
    })

    const handleQueryData = () => {
      fetchData()
    }

    // 条数
    const handleSizeChange = (pageSize) => {
      state.queryForm.pageSize = pageSize
      fetchData()
    }
    // 页码
    const handleCurrentChange = (pageNum) => {
      state.queryForm.pageNum = pageNum
      fetchData()
    }

    const handleDate = (val) => {
      return parseTime(val)
    }

    const handleQrcode = (row) => {
      getQrcodeUrls(row.meetingCode, 'HOLD_MEETING').then((res) => {
        console.log(res)
        state.qrcodeUrlList = res.data
        state.dialogVisible = true
      })
    }

    // 取消关联
    const cancelRelatedMeetFunc = async (row) => {
      if (!!row.meetingCode) {
        proxy.$baseConfirm(
          '取消会议的关联，会导致展会的论坛列表不再显示这场会议。',
          null,
          async () => {
            const { msg } = await cancelRelatedMeet({
              hostUid: state.queryForm.hostUid,
              meetingCode: row.meetingCode,
              exMeetingCode: state.queryForm.exMeetingCode,
            })
            proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await fetchData()
          }
        )
      }
    }

    const handleRelatedMeetFunc = async (row) => {
      // const { msg } = await doRelatedMeet({
      //   hostUid: state.queryForm.hostUid,
      //   meetingCode: row.meetingCode,
      //   exMeetingCode: state.queryForm.exMeetingCode,
      // })
      // proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
      // await fetchData()
      // 已经关联过的状态，通过get 接口查询信息
      if (row.status) {
        const { data } = await getRelatedMeetDetail({
          meetingCode: row.meetingCode,
        })
        data.status = row.status
        state['editRef'].showEdit(data)
      } else {
        // 没有关联的状态，自定义拼接信息
        const params = {
          hostUid: row.uid,
          status: row.status,
          meetingCode: row.meetingCode,
          exMeetingCode: state.queryForm.exMeetingCode,
          meetingName: row.meetingName,
          coverUrl: row.coverUrl,
        }
        state['editRef'].showEdit(params)
      }
    }
    return {
      ...toRefs(state),
      handleClick,
      handleEdit,
      cancelRelatedMeetFunc,
      fetchData,
      handleDate,
      handleRelatedMeetFunc,
      handleQueryData,
      handleQrcode,
      handleSizeChange,
      handleCurrentChange,
    }
  },
})
</script>
<style lang='scss' scoped>
</style>

